<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('notification.templateNotifyInfo')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="notificationContentTitle"
                  :label="$t('templateEmail.name')"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
                style="display: flex;"
              >
                <v-autocomplete
                  id="templateFieldValue"
                  v-model="notificationContentField"
                  :items="notificationContentFields"
                  :clearable="true"
                  :label="$t('templateEmail.chooseValue')"
                  type="text"
                  class="required"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-autocomplete>
                <v-tooltip
                  top>
                  <template
                    v-slot:activator="{ on }"
                  >
                    <v-icon
                      v-clipboard:copy="notificationContentField"
                      v-clipboard:success="onCopy"
                      class="ml-2 add-text"
                      style="cursor: pointer;"
                      color="success"
                      v-on="on">mdi-message-plus</v-icon>
                  </template>
                  <span>{{ $t("common.insert") }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-form>
        </material-card>
        <material-card
          :title="$t('notification.contentTemplateNotify')"
          color="green"
          flat
          full-width
        >
          <ckeditor
            :editor="editor"
            v-model="editorData"
            :config="editorConfig"
            @ready="onEditorReady"
          />
        </material-card>
        <v-btn
          class="white--text"
          color="red"
          style="margin-left: 0; margin-right: 5px;"
          @click="onClose()"
        >
          <span class="btn-label">
            <i
              class="glyphicon glyphicon-remove"
              style="margin-right: 5px;"/>
          </span>
          {{ $t('common.close') }}
        </v-btn>
        <v-btn
          color="primary"
          style="color: #ffffff; margin: 0;"
          @click="onSaveNotificationContent"
        >
          <v-icon class="mr-2">mdi-content-save-move-outline</v-icon>
          <span style="text-transform: none;">{{ $t('common.save') }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <!-- <previewTemplateDefaultModal
      ref="previewTemplateDefault"
      @onConfirm="onUsedThisTemplate" /> -->
  </v-container>
</template>

<script>
import '@ckeditor/ckeditor5-build-classic/build/translations/vi'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
import { mapActions, mapGetters } from 'vuex'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
// import PreviewTemplateDefaultModal from './PreviewTemplateDefault'
export default {
  components: {
    // PreviewTemplateDefaultModal
  },
  data () {
    return {
      editor: ClassicEditor,
      editorData: '<p>Nhập nội dung.</p>',
      editorConfig: {
        // The configuration of the editor.
        plugins: [ Table, EssentialsPlugin, BoldPlugin, ItalicPlugin, LinkPlugin, ParagraphPlugin, EasyImage, Heading, ImageCaption, ImageStyle, ImageToolbar, TableToolbar, TableProperties, TableCellProperties, Alignment, ListStyle, ImageInsert, Base64UploadAdapter, SimpleUploadAdapter, HtmlEmbed ],
        toolbar: {
          items: [
            // 'heading',
            // '|',
            // 'alignment',
            // 'bold',
            // 'italic',
            // '|',
            // 'link',
            // 'insertTable',
            // '|',
            // 'bulletedList',
            // 'numberedList',
            // '|',
            // 'imageInsert',
            // 'blockQuote',
            // '|',
            // 'undo',
            // 'redo',
            // 'htmlEmbed'
          ]
        },
        image: {
          toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn', 'tableRow', 'mergeTableCells',
            'tableProperties', 'tableCellProperties', 'insertTableRowAbove'
          ]
        },
        language: 'vi'
      },
      notificationContentTitle: null,
      notificationContentFields: [],
      notificationContentField: null,
      copiedText: null,
      valid: false
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  created () {
    let notificationId = this.$route.params.notificationId
    if (!functionUtils.isNull(notificationId)) {
      this.GET_NOTIFICATION_CONTENT_DETAIL({ id: notificationId }).then(
        function (res) {
          let data = res.data
          this.editorData = data.content
          this.notificationContentTitle = data.title
        }.bind(this)
      )
    }
    let categoryId = this.$route.params.categoryId
    if (!functionUtils.isNull(categoryId)) {
      let filter = {
        params: {
          categoryId: categoryId
        }
      }
      this.GET_FIELDS_NOTIFICATION_CONTENT_BY_CATEGORY(filter).then(
        function (res) {
          let data = res.data
          this.notificationContentFields = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              value: data[i].value,
              text: data[i].name
            }
            this.notificationContentFields.push(obj)
          }
        }.bind(this)
      )
    }
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    onCopy: function (e) {
      this.copiedText = e.text
      this.ON_SHOW_TOAST({
        message: this.$t('common.success'),
        styleType: ToastType.SUCCESS
      })
    },
    onEditorReady (editor) {
      document.querySelector('.add-text').addEventListener('click', () => {
        this.addTag(editor)
      })
    },
    addTag (editor) {
      editor.model.change(writer => {
        const insertPosition = editor.model.document.selection.getFirstPosition()
        writer.insertText(this.copiedText, insertPosition)
      })
    },
    onError: function (e) {
      alert('Failed to copy texts')
    },
    /**
     * Used this template
     */
    onUsedThisTemplate: function (htmlContent) {
      this.editorData = htmlContent
    },
    /**
     * Preview template
     */
    onPreviewTemplate: function (value) {
      this.$refs.previewTemplateDefault.onShowModal(value)
    },
    /**
     * Go back
     */
    onClose: function () {
      window.history.back()
    },
    /**
     * Save template
     */
    onSaveNotificationContent: function () {
      if (this.$refs.form.validate()) {
        let notificationId = this.$route.params.notificationId
        if (functionUtils.isNull(notificationId)) {
          let categoryId = this.$route.params.categoryId
          let filter = {
            title: this.notificationContentTitle,
            categoryId: categoryId,
            content: this.editorData
          }
          this.CREATE_NOTIFICATION_CONTENT(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }.bind(this)
          )
        } else {
          let filter = {
            content: this.editorData,
            title: this.notificationContentTitle
          }
          this.UPDATE_NOTIFICATION_CONTENT({ id: notificationId, ...filter }).then(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }.bind(this)
          )
        }
      }
    },
    onReady (editor) {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      )
    },
    ...mapActions([
      'CREATE_NOTIFICATION_CONTENT',
      'UPDATE_NOTIFICATION_CONTENT',
      'ON_SHOW_TOAST',
      'GET_NOTIFICATION_CONTENT_DETAIL',
      'GET_FIELDS_NOTIFICATION_CONTENT_BY_CATEGORY'
    ])
  }
}
</script>

<style lang="scss" scoped>
.bg-white {
  background-color: #fff !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
